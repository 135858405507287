/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

import moduleAdmin from "./admin/moduleAdmin.js";
import moduleAuth from "./auth/moduleAuth.js";
import moduleUser from "./user-management/moduleUserManagement.js";
import modulePartner from "./partner/modulePartner";
import moduleMerchant from "./merchant/moduleMerchant";

import moduleListSetting from "./listSettings/moduleListSetting";
import modulePaymentPlan from "./paymentPlan/modulePaymentPlan";
import moduleS3Service from "./s3Service/moduleS3Service";
import moduleLogo from "./logo/moduleLogo";
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    admin: moduleAdmin,
    user: moduleUser,
    partner: modulePartner,
    listSetting: moduleListSetting,
    merchant: moduleMerchant,
    paymentPlan: modulePaymentPlan,
    s3ServiceModule: moduleS3Service,
    logo: moduleLogo,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [createPersistedState()],
});
