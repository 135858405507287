/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import { auth } from "@/auth/auth";
import "firebase/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/login",
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/admin/Dashboard.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/partners/:id/show",
          name: "partners-detail",
          component: () =>
            import("@/views/pages/admin/partners/PartnerDetail.vue"),
          meta: {
            pageTitle: "Merchant detail",
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/settings/",
          name: "settings",
          component: () => import("@/views/pages/admin/settings/Settings.vue"),
          meta: {
            pageTitle: "Settings",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/merchants/",
          name: "merchant-list",
          component: () => import("@/views/pages/partners/MerchantList.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchants", active: true },
            ],
          },
        },
        {
          path: "/merchants/:id/show",
          name: "merchant-detail",
          component: () => import("@/views/pages/partners/MerchantDetail.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchants", url: "/merchants" },
              { title: "View merchant", active: true },
            ],
          },
        },
        {
          path: "/products",
          name: "product-list",
          component: () => import("@/views/pages/products/ProductList.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Enabled products", active: true },
            ],
          },
        },
        {
          path: "/products/:id/show",
          name: "product-detail",
          component: () => import("@/views/pages/products/ProductDetail.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Enabled products", url: "/products" },
              { title: "View product", active: true },
            ],
          },
          beforeEnter: (to, from , next) => {
            to.meta.breadcrumb[2].title = store.getters.getProductTitle;
            next();
          },
        },
        {
          path: "/users",
          name: "user-list",
          component: () => import("@/views/pages/users/ListUser.vue"),
          meta: {
            pageTitle: "",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Users", active: true },
            ],
          },
        },
        {
          path: "/users/create",
          name: "users-create",
          component: () => import("@/views/pages/users/UserForm.vue"),
          meta: {
            pageTitle: "Create new user",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Users", url: "/users" },
              { title: "Create new user", active: true },
            ],
          },
        },
        {
          path: "/users/:id/edit",
          name: "user-edit",
          component: () => import("@/views/pages/users/UserForm.vue"),
          meta: {
            pageTitle: "Edit user",
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Users", url: "/users" },
              { title: "Edit user", active: true },
            ],
          },
        },
        {
          path: "/platform-profile",
          name: "platform-profile",
          component: () =>
            import("@/views/pages/platformProfile/PlatformProfile.vue"),
          meta: {
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Platform profile", active: true },
            ],
          },
        },
        {
          path: "/change-password",
          name: "admin-change-password",
          component: () => import("@/views/pages/admin/ChangePassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/logo-library",
          name: "logo-library",
          component: () =>
          import("@/views/pages/logoLibrary/index.vue"),
          meta: {
            requiresAuth: true,
            rule: "editor",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Logo library", active: true },
            ],
          },
        },
        {
          path: "/view/profile",
          name: "admin-view-profile",
          component: () => import("@/views/pages/adminProfileNew.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/login",
          name: "introducer-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/login-as-introducer",
          name: "login-as-introducer",
          component: () => import("@/views/pages/login/LoginAsIntroducer.vue"),
          meta: {
            rule: "editor",
            // requiresAuth: true
          },
        },

        {
          path: "/forgotPassword",
          name: "forgot-admin-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            requiresAuth: false,
            rule: "editor",
          },
        },

        {
          path: "/resend-verification",
          name: "page-resend-verification",
          component: () => import("@/views/pages/ResendVerification"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/verifyMail", payload)
              .then(() => {
                return next();
                //next("/admin/login");
              })
              .catch((error) => {

                return next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/verificationSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password/:token",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/admin/reset-password",
          name: "reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/admin/reset-password/:token",
          name: "admin-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/terms-and-conditions/:slug",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || process.env.VUE_APP_NAME;
});

//this method is calling in each router navigate
//commented cause of reset-password link doesn't required to auth
router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta.requiresAuth
    ) /*&& to.name !== 'page-login'*/
  ) {
    auth(to, from, next);
  } else {
    return next();
  }
});

export default router;
