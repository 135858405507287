/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";
import {format} from 'date-fns'

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);
var VueTruncate = require("vue-truncate-filter");
Vue.use(VueTruncate);

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// Theme Configurations
import "../themeConfig.js";

// Firebase
import "@/firebase/firebaseConfig";

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);
import VueScrollTo from "vue-scrollto";
// import "vue2-datepicker/index.css";
Vue.use(VueScrollTo);

// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

// VeeValidate
import VeeValidate from "vee-validate";
VeeValidate.setMode("eager");

VeeValidate.Validator.localize("en", {
  custom: {
    password: {
      regex:
        "Password must contain at least 8 characters including one uppercase letter,one lowercase letter and one number."
    }
  }
});

Vue.use(VeeValidate, {
  classes: true
});

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "YOUR_API_KEY",
    libraries: "places" // This is required if you use the Auto complete plug-in
  }
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Sentry logging
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_SENTRY_ORIGINS, /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_RATE),
  ignoreErrors: [
    'Error',
    /^401$/,
    /^410$/,
  ],
});

// custom directive to make button rounded

Vue.directive("round", {
  bind(el, binding, vnode) {
    el.style.borderRadius = "30px";
  },
});

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 30,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.message === toast.message
    ).length !== 0) {
      return false;
    }
    return toast;
  }
});

Vue.mixin({
  methods: {
    formatDateOrder(value) {
      return format(new Date(value), "dd/MM/yyyy");
    },

    showToastMessage(title = "Success", message = "Success", type = "success") {
      let toastDetail = {};
      const icon = {
        iconClass: "",
        iconChildren: "",
        iconTag: "span"
      };
      toastDetail.position = "bottom-left";
      toastDetail.closeOnClick = false;
      toastDetail.pauseOnFocusLoss = false;
      toastDetail.pauseOnHover = false;
      toastDetail.draggable = false;
      toastDetail.draggablePercent = 0.1;
      toastDetail.showCloseButtonOnHover = false;
      toastDetail.hideProgressBar = true;
      toastDetail.closeButton = "button";
      toastDetail.icon = icon;
      toastDetail.rtl = false;
      toastDetail.message = message;

      if (type == "success") {
        toastDetail.icon.iconClass = "material-icons";
        toastDetail.icon.iconChildren = "check_circle_outline";
        this.$toast.success(<div class="container"><h3>{ title }</h3><p class="empty:hidden">{ message }</p></div>, toastDetail);
      } else {
        toastDetail.timeout = false,
        toastDetail.icon.iconClass = "material-icons";
        toastDetail.icon.iconChildren = "highlight_off";
        this.$toast.error(<div class="container"><h3>{ title }</h3><p class="empty:hidden">{ message }</p></div>, toastDetail);
      }
    },
  }
});

// formats currency as $123,56.67
Vue.mixin({
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
    }
  },
  computed: {
    isFormDirty() {
      return Object.keys(this.fields).some(key => this.fields[key].dirty);
    },
    validateForm() {
      return !this.errors.any();
    },
  }
});

// Feather font icon
require("./assets/css/iconfont.css");

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  acl,
  watch: {
    $route: {
      handler(to, from){
        if ( to.name !== from.name ) {
          if ( localStorage.getItem('formFieldUpdated')
            && localStorage.getItem('formFieldUpdated') === 'yes'
          ) {
            localStorage.setItem('formFieldUpdated', 'no')
          }
          if ( localStorage.getItem('redirectTo')
            && localStorage.getItem('redirectTo') !== 'introducer-login'
          ) {
            localStorage.setItem('redirectTo', undefined)
          }
        }
      },
      flush: 'post',
    }
  },
  render: h => h(App)
}).$mount("#app");

